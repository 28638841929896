<template>
    <div class="collectPage overflow" ref="collectPage">
        <!-- 按钮组start -->
        <div class="collectTop  flex">
            <div class="collectLeftTop  flex flexSpace" ref="collectLeftTop">
                <span class="cLeftBtn baseMax yellowBg textCenter cursor" :class="{ cur: current == item.id }"
                    v-for="(item, index) in btnGroup" @click="btnMethod(item.id,true)">{{item.name}}</span>
            </div>
            <div class="cLeftSearch flex">
                <input type="text" placeholder="输入关键词搜索（可最多同时输入3个词且必须逗号隔开）" class="textCenter baseMax"
                    v-model="keyWord" />
                <el-select v-model="groupType" placeholder="不限类型" class=" baseMax">
                    <el-option v-for="item in groupOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-select v-model="personNum" placeholder="不限人数" class=" baseMax ">
                    <el-option v-for="item in personNumOptions" :key="item.value" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <el-select v-model="area" placeholder="地区选择" class=" baseMax ">
                    <el-option v-for="item in areaOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-select v-model="dateValue" placeholder="日期选择" class=" baseMax">
                    <el-option v-for="(item,index) in updateOptions" :key="index" :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <p class="collectCodeTitle baseMax textCenter  downKeyWord  cursor yellowBg" @click="changeMethod(3)">
                    开始筛选</p>
                <p class="collectCodeTitle baseMax textCenter  downKeyWord restBtn cursor yellowBg"
                    @click="restMethod(true)">重置刷新</p>
            </div>
        </div>
        <!-- 按钮组end -->
        <div class="collectContainer flex overflow">
            <div class="collextLeft overflow">
                <!-- 表格start -->
                <div class="wxTable">
                    <tablelist :tableArray="tableArray" :defaultTable="defaultTable" @checkMethod="checkMethod"
                        ref="table" @doubleClick="doubleClick" type="wxcollect" @loadMore="loadMore">
                    </tablelist>
                </div>
                <!-- 表格end -->
                <div class="collectBottom overflow flex" ref="collectBottom">
                    <li @click="delMethod(1)" class="cursor">
                        <img src="../../public/btn.png">
                        <p class="textCenter  baseMax ">清空已查看</p>
                    </li>
                    <li @click="delMethod(2)" class="cursor">
                        <img src="../../public/btn.png">
                        <p class="textCenter  baseMax">清空已下载</p>
                    </li>
                    <li @click="downCallBack" class="cursor">
                        <img src="../../public/btn.png">
                        <p class="textCenter  baseMax ">{{downText}}</p>
                    </li>
                    <li @click="downOperate" class="cursor">
                        <img src="../../public/btn.png">
                        <p class="textCenter  baseMax ">我的下载</p>
                    </li>
                    <li @click="$emit('changeMenu', 'downsetting')" class="cursor">
                        <img src="../../public/btn.png">
                        <p class="textCenter  baseMax ">下载设置</p>
                    </li>
                    <li @click="setBlockingWord" class="cursor">
                        <img src="../../public/btn.png">
                        <p class="textCenter  baseMax">批量下载屏蔽词</p>
                    </li>
                </div>
                <!-- 加载动画start -->
                <loadAction :loading="isLoad" />
                <!-- 加载动画end -->
            </div>
            <div class="collectRight overflow">
                <div class="collectCode overflow">
                    <!-- drag -->
                    <p class="collectCodeTitle baseMax textCenter" ref="codeTitle">二维码展示区</p>
                    <div class="codeText overflow">
                        <div class="codePic codeImg flex" :class="{'flexCenter codeActive':!codeList}">
                            <div class="codeShow" v-if="codeList">
                                <img :src="codeList.group_qr_code" alt="" class="drag">
                                <div class="codeShowText">
                                    <p>群类型：{{codeList.group_type_desc}}<span
                                            class="codeShowTextMargin">群内人数：{{codeList.number_of_people?codeList.number_of_people:0}}人</span>
                                    </p>
                                    <p>群名称：{{codeList.name}}</p>
                                    <p>采集时间：{{codeList.create_time}}</p>
                                    <p>说明：因群主可能随时都会修改群名，以及群内人数随着时间而变化，所以软件只保证更新的那一秒显示的参数和实际一致，时间久了是不受我们控制的。</p>
                                </div>
                            </div>
                            <!-- 无数据时start v-else-->
                            <div v-else class="noData overflow">
                                <p class="textCenter baseMax">双击群名</p>
                                <p class="textCenter baseMax overflow flex flexCenter">出现二维码 <img
                                        src="../assets/mouse.png" alt="" class="mouse"></p>
                            </div>
                            <!-- 无数据时end -->
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <!-- 蒙层（如果用户没有充值的话） -->
        <div class="indexShade" v-if="isRechange&& groupSet.not_opened_list_mode==0" @click="titleMethod"></div>
        <!--下载弹窗 -->
        <el-dialog :title="downParams.title" :visible.sync="dialogVisible" top="32vh">
            <div class="downDialog">
                <li v-if="checkList.length==0">
                    <p>下载数量：</p>
                    <div class="downRadio downButton flex">
                        <span v-for="(item,index) in downDrop.dataNum" class="buttonLi"
                            :class="{'active':downParams.chooseNum==item}" :key="index"
                            @click="verifyMethod(item)">{{item}}</span>
                        <el-input v-model="downParams.custom" placeholder="请输入自定义数量" type="text"
                            v-if="downParams.chooseNum=='自定义'" @change="verifyMethod(item,true)"></el-input>
                    </div>
                </li>
                <li>
                    <p>保存类型：</p>
                    <div class="downRadio">
                        <RadioGroup v-model="downParams.type">
                            <Radio :label="index+1" v-for="(item,index) in downDrop.dataType" :key="index">{{item}}
                            </Radio>
                            <!-- <Radio :label="6">链接</Radio> -->
                        </RadioGroup>
                    </div>
                </li>
                <li>
                    <p class="fontweight baseFour">温馨提示：下载过的群，重复下载不扣积分，只统计扣除新增数据的积分。</p>
                </li>
                <!-- 勾选 -->
                <li v-if="checkList.length!=0">
                    <p class="downToast">{{checkTitle}}
                    </p>
                </li>
                <li v-else>
                    <p class="downToast">
                        {{maxList?.msg}}
                    </p>
                </li>

            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" size="small" @click="sureDownMethod">确定下载</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import api from '../../http/api';
    import { downFile, downFileExt, appendText, isDownAddress, addConsoleTxt, openPath } from '../../utils/win';
    import { nowDataMethod, getUser, guid, getDays } from '../../utils/index';
    import { MessageBox, Message, Radio, RadioGroup, TimePicker, Loading } from 'element-ui';
    export default {
        props: ['downCount',],
        data() {
            return {
                // downTitle:'',//下载提示语
                // loadTime:'',//时间段下载
                loadChoose: 1,//下载选择
                //loadArray:[{id:1,value:'下载数量',},{id:2,value:'按时间段下载',}],//下载范围
                txtTime: '',//txt定时
                maxList: '',//最大积分
                downDrop: {
                    dataTimeRange: [{ id: 1, value: '下载数量', }, { id: 2, value: '按时间段下载', }],//下载范围
                    // dataType: ['图片', '链接'],
                    dataType: ['二维码图片带群名', '二维码图片','链接'],
                    dataTime: ['今天', '昨天', '七天内'],
                    dataNum: ['10', '50', '100', '300', '500', '1000', '自定义'],
                },//下拉选项
                downParams: {
                    loadTime: '',//下载时间段
                    txtName: '',//txt下载的文件名
                    downDic:'',
                    chooseNum: '',//下载数量
                    downType: 1,//默认下载个人群
                    title: '',//标题
                    time: 1,//时间
                    type: 1,//类型 1是下载图片文件名是群名 2是时间戳-索引  3 是文件链接
                    custom: '',//自定义数量
                },
                clonedownParams: {},//克隆下载选项
                dialogVisible: false,//下载弹窗
                iserror: false,//下载是否报错
                nowTime: '',//当前日期
                isDown: true,//是否能下载
                downTotal: 0,//下载数量
                loadIndex: -1,//下载是否加载动画
                loadDownMenu: [{
                    key: 1,
                    value: '下载个人群',
                },
                {
                    key: 5,
                    value: '下载企业群',
                },
                {
                    key: 3,
                    value: '已搜索下载',
                },
                {
                    key: 4,
                    value: '地区筛选下载',
                },
                {
                    key: 2,
                    value: '下载已勾选',
                },
                ],//下载菜单
                elTop: 0,//
                autoplay: true,//自动轮播
                codeArray: [1, 2],
                // codeList: '',//二维码链接
                codeList: '',//二维码内容
                // paramsBtn: {
                //     isKeyWord: true,//关键词
                //     isArea: true//区域
                // },
                isScrollTop: false,//是否滚动条回到顶部
                isLoad: false,  //加载动画
                isClear: true,//清除定时
                current: -1,//默认选中 这块用来判断
                //列表采集过滤
                btnGroup: [{ name: '最近7天', isCurrent: false, id: 0, }],
                // btnGroup: [{ name: '最近7天', isCurrent: false, id: 0, }, { id: 1, name: '只看个人群', isCurrent: false }, { id: 2, name: '只看企业群', isCurrent: false }],
                // btnGroup: [{ name: '采集个人群', isCurrent: false }, { name: '采集企业群', isCurrent: false }],//采集数组
                params: {
                    group_type: 0, //群组类型 1个人群 2企业群
                    name: '', //采集关键词
                    province_id: -1,//区域
                    page_size: 20,
                    page_index: 1,
                    start_time: null,//开始时间
                    end_time: null,//结束时间
                    start_id: 0, //开始id
                    end_id: 0,  //结束id
                    start_num: 0,//开始数量
                    end_num: 0,//结束数量
                },
                loadAnimation: '', //加载动画
                isSearch: false,//是否开始搜索
                keyWord: '',//关键词
                areaOptions: [],
                groupOptions: [ //不限类型下拉
                    { label: '不限类型', value: 0 },
                    { label: '个人群', value: 1 },
                    { label: '企业群', value: 2 }
                ],
                personNum: '0-0',//人数
                //不限人数下拉
                personNumOptions: [
                    { label: '不限人数', value: '0-0' },
                    { label: '20人以上', value: '20-500' },
                    { label: '50人以上', value: '50-500' },
                    { label: '100人以上', value: '100-500' },
                ],
                dateValue: getDays(7)[getDays(7).length - 1] + ' 0:00:00' + '&&' + nowDataMethod() + ' 23:59:59',//更新
                //更新下拉
                updateOptions: [
                    { label: '近7天内更新', value: getDays(7)[getDays(7).length - 1] + ' 0:00:00' + '&&' + nowDataMethod() + ' 23:59:59' },
                    { label: '近3天内更新', value: getDays(3)[getDays(3).length - 1] + ' 0:00:00' + '&&' + nowDataMethod() + ' 23:59:59' },
                    { label: '近2天内更新', value: getDays(2)[getDays(2).length - 1] + ' 0:00:00' + '&&' + nowDataMethod() + ' 23:59:59', },
                    { label: '昨天更新', value: getDays(1)[1] + ' 0:00:00' + '&&' + getDays(1)[1] + ' 23:59:59', },
                    { label: '今天更新', value: nowDataMethod() + ' 0:00:00' + '&&' + nowDataMethod() + ' 23:59:59' },
                ],
                groupType: 0,//不限类型
                area: -1,//地区选择
                tableH: 0,//二维码的高度
                screenWidth: '',
                timerMs: null,//列表定时器
                timer: '',
                isDownCheck: false,//下载是否有勾选的数据
                checkList: [],
                checkMsg: {},//取到勾选积分的值
                checkTitle: '',//勾选提示
                errorArray: [],//下载失败
                tableArray: [{
                    field: 'group_type_desc',
                    width: '100',
                    title: '类型',
                    slots: {
                        default: ({ row }) => {
                            return [
                                <span>{row.group_type_desc}</span>,
                            ]
                        }
                    }
                },
                {
                    field: 'name',
                    width: '210',
                    title: '群名称',
                    slots: {
                        default: ({ row }) => {
                            return [
                                <span>{row.name}</span>,
                            ]
                        }
                    }
                },
                {
                    field: 'number_of_people',
                    width: '100',
                    title: '群人数',
                    slots: {
                        default: ({ row }) => {
                            return [
                                <span>{row.number_of_people}</span>,
                            ]
                        }
                    }
                },
                // {
                //     field: 'expiry_date',
                //     width: '12%',
                //     title: '有效期',
                //     slots: {
                //         default: ({ row, column }) => {
                //             return [
                //                 <span>{row.expiry_date}</span>,
                //             ]
                //         }
                //     }
                // },
                {
                    field: 'create_time',
                    width: '200',
                    title: '采集时间',
                    slots: {
                        default: ({ row }) => {
                            return [
                                <span>{row.create_time}</span>,
                            ]
                        }
                    }
                },
                {
                    field: 'need_points',
                    width: '100',
                    title: '所需积分',
                    slots: {
                        default: ({ row }) => {
                            return [
                                <span>{row.need_points}</span>,
                            ]
                        }
                    }
                },
                {
                    field: 'is_to_view',
                    width: '80',
                    title: '是否查看',
                    slots: {
                        default: ({ row }) => {
                            return [
                                <span>{row.is_to_view}</span>,
                            ]
                        }
                    }
                },
                {
                    field: 'is_download',
                    width: '80',
                    title: '是否下载',
                    slots: {
                        default: ({ row }) => {
                            return [
                                <span>{row.is_download}</span>,
                            ]
                        }
                    }
                },
                {
                    field: 'operate',
                    width: '50',
                    title: '勾选',
                },
                ],
                downCompelte: [],//下载完成唯一标识集合
                rememberParams: 0,//记住筛选条件 1是个人群，2企业群3搜索群 4地区下载 0下载默认7天(默认全部)
                defaultTable: [], //采集数据
                downPwd: '',//下载密钥
                isLoadMore: true,//是否下拉加载
            }
        },
        watch: {
            clearRest: {
                handler(nval, oval) {
                    if (nval) {
                        this.isDown = false;
                        this.$refs.table && this.$refs.table.gridLoad(false);
                        //this.loading = false;
                        this.rest(true);
                    }
                }
            }

        },
        computed: {
            ...mapState(['isRechange', 'info', 'isStop','groupSet']), //
            //408的时候的时候清除loading等操作
            clearRest() {
                return this.isRechange;
            },
            downNumber() {
                return this.downParams.chooseNum == '自定义' ? (this.downParams.custom ? this.downParams.custom : 0) : (this.downParams.chooseNum ? this.downParams.chooseNum : 0)
            },
            // countNumber: {
            //     get() {
            //         return this.params.group_type ? (this.params.group_type != 1 ? 5 : 1) : (this.keyWord ? 3 : (this.area ? 4 : -1))
            //     },
            // },
            downText() {
                return this.isDownCheck ? '下载已勾选' : (this.rememberParams == 1 ? '下载个人群' : (this.rememberParams == 2 ? '下载企业群' : (this.rememberParams == 3 ? '下载已筛选' : '批量下载')))
            }

        },
        components: {
            loadAction: () => import("../components/load"),
            'tablelist': () => import('../components/table'),
            Radio,
            RadioGroup,
            TimePicker
        },
        created() {
            let that = this;
            that.clonedownParams = JSON.parse(JSON.stringify(this.downParams))
        },
        mounted() {
        },
        async activated() {
            this.$emit('closeCircle', false)
            this.rest();
            this.restMethod();
            await getUser()
            this.getArea();
            this.defaultTable = [];
            if (this.isRechange && this.groupSet.not_opened_list_mode==0) {
                this.$refs.table.description = '积分不足，请先充值。'
            }
            this.isSearch=false
            this.btnMethod(0)
        },
        deactivated() {
            this.clear()
        },
        methods: {
            //首页刷新
            indexReload() {
                this.isSearch = false;
                this.btnMethod(0)
            },
            //我的下载
            downOperate() {
                MessageBox.confirm("此操作会打开本地文件，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }).then((action) => {
                    if (action == "confirm") {
                        top.window.require && openPath(this.info.save_address);
                    }
                });
            },
            //更多功能
            moreOperate() {
                Message({
                    message: '更多功能开发中...',
                    type: 'info'
                })
            },
            formatChineseDate(date) {
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const hours = date.getHours();
                const minutes = date.getMinutes();
                const seconds = date.getSeconds();
                const formattedDate = `${year}年${month}月${day}日${hours.toString().padStart(2, '0')}时${minutes.toString().padStart(2, '0')}分${seconds.toString().padStart(2, '0')}秒`;
                return formattedDate;
             },
            //时间段
            timePickerMethod() {
                //  console.log('downParams.loadTime', this.downParams.loadTime)
                this.downParams.loadTime && this.downParams.loadTime.length != 0 ? this.downMaxNumber(true) : (this.maxList = '')
            },
            downRadioMethod() {
                //console.log('loadChoose',this.loadChoose) 
                this.$set(this.downParams, 'time', 1)
                this.maxList = '';
                if (this.loadChoose == 2) {
                    this.$set(this.downParams, 'chooseNum', '')
                    this.downParams.custom = '';
                } else {
                    this.$set(this.downParams, 'loadTime', '')
                }
            },
            async verifyMethod(num, isChange) {
                let that = this;
                that.downParams.chooseNum != '自定义' && (that.downParams.custom = '')
                !isChange && that.$set(that.downParams, 'chooseNum', num)
                await that.downMaxNumber();
                let timeText = that.updateOptions.filter(item => item.value == that.dateValue)[0].label
                //console.log('num', num, that.maxList, timeText)
                if (that.downParams.chooseNum && ((that.downParams.chooseNum != '自定义' && that.downParams.chooseNum > that.maxList.download_num) || that.downParams.chooseNum == '自定义' && that.downParams.custom > that.maxList.download_num)) {
                    Message({
                        message: that.maxList.download_num ? `${timeText}只有${that.maxList.download_num}条可以下载~` : `暂无下载数据`,
                        type: 'error'
                    });
                    return;
                }
            },
            //重置操作
            restMethod(isRefresh) {
                this.isSearch = false
                this.personNum = '0-0'
                //需要重置一下，要不然凌晨时间不对
                this.updateOptions = [
                    { label: '近7天内更新', value: getDays(7)[getDays(7).length - 1] + ' 0:00:00' + '&&' + nowDataMethod() + ' 23:59:59' },
                    { label: '近3天内更新', value: getDays(3)[getDays(3).length - 1] + ' 0:00:00' + '&&' + nowDataMethod() + ' 23:59:59' },
                    { label: '近2天内更新', value: getDays(2)[getDays(2).length - 1] + ' 0:00:00' + '&&' + nowDataMethod() + ' 23:59:59', },
                    { label: '昨天更新', value: getDays(1)[1] + ' 0:00:00' + '&&' + getDays(1)[1] + ' 23:59:59', },
                    { label: '今天更新', value: nowDataMethod() + ' 0:00:00' + '&&' + nowDataMethod() + ' 23:59:59' },
                ];
                this.dateValue = getDays(7)[getDays(7).length - 1] + ' 0:00:00' + '&&' + nowDataMethod() + ' 23:59:59';
                this.area = -1;
                this.groupType = 0;
                this.keyWord = '';
                isRefresh && this.btnMethod(0)
            },
            //还原筛选
            rest(data) {
                // console.log('还原所有选项---', this.paramsBtn)

                // this.restMethod();
                // this.checkList = []
                this.isDownCheck = false; //要清除
                //this.btnGroup = [{ name: '最近7天', isCurrent: false, id: 0, }, { id: 1, name: '只看个人群', isCurrent: false }, { id: 2, name: '只看企业群', isCurrent: false }]
                //this.btnGroup = [{ name: '采集个人群', isCurrent: false }, { name: '采集企业群', isCurrent: false }]
                this.clear(data);
            },
            //双击要处理的事
            doubleClick(data) {
                if(!this.isRechange) {
                    let that = this;
                    that.isLoad = true;
                    // that.$refs.table.gridLoad(true)
                    api.getToview({
                        operation: data.aunique_identifier,
                    }).then(res => {
                        that.isLoad = false;
                        // that.$refs.table.gridLoad(false)
                        getUser()
                        if (res.code == 200 && res.response) {
                            let index = that.defaultTable.findIndex(item => item.aunique_identifier == data.aunique_identifier)
                            if (index > -1) {
                                that.defaultTable[index].is_to_view = '是';
                                // that.$refs.table && that.$refs.table.updateMwthod();
                            }
                            // console.log('双击要查看的图片', res)
                            that.codeList = res.response ? res.response : ''
                        } else {
                            Message({
                                message: res.msg,
                                type: 'error'
                            });
                        }
                    })
                }
                else{
                    this.titleMethod()
                }
            },
            downCallBack() {  //6是默认下载7天  3搜索
                this.downMethod(this.isDownCheck ? 2 : (this.rememberParams == 1 ? 1 : (this.rememberParams == 2 ? 5 : (this.rememberParams == 3 ? 3 : 6))))
            },
            setBlockingWord(){ // 设置下载词
                if (!this.isRechange) {
                    this.$emit('isShield',true)
                }
                else{
                    this.titleMethod()
                }
            },
            //下载6是默认下载7天  3搜索 2已勾选  1个人 5是企业  2是已勾选
            async downMethod(index) {
                let that = this;
                if (!that.isRechange) {
                    that.isDown = true;
                    that.iserror = false;
                    that.$emit('closeCircle', false)
                    if (!that.info.save_address) {
                        MessageBox.confirm('您还未设置图片下载位置，请点击确定按钮前往设置！', '提示', {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                            type: 'warning'
                        }).then((action) => {
                            if (action == 'confirm') {
                                this.$emit('changeMenu', 'downsetting')
                            }
                        })
                        that.loadIndex = -1;
                        return;
                    }

                    if (index == 2) {
                        that.downCompelte = [];
                        if (that.checkList.length == 0) {
                            Message({
                                message: `请至少勾选一条下载数据~`,
                                type: 'warning'
                            });
                            return;
                        }

                        let data = {
                            group_type: that.params.group_type,
                            name: that.params.name,
                            province_id: that.params.province_id,
                        }
                        let needJf = await api.checkNeed({
                            ...data,
                            aunique_identifier_list: that.checkList,
                        })
                        if (needJf.response.is_download) {
                            that.checkMsg = needJf.response
                            that.checkTitle = that.checkMsg.msg
                        } else {
                            MessageBox.alert(needJf.response.msg, '温馨提示', {
                                confirmButtonText: '取消',
                                callback: action => { }
                            })
                            return;
                        }
                        // that.downPicMethod(2)
                    } else {
                        that.checkList = [];
                    }
                    that.$emit('initCount')
                    that.downNewMethod(index)
                }
                else {
                    this.titleMethod();
                }
            },
            //  图片,txt和勾选下载 1txt 0图片带群名 2勾选 3是图片带时间戳-索引
            async downPicMethod(index) {
                let that = this;
                let isCheck = that.checkList.length != 0 ? true : false
                //console.log('downPicMethod', that.downTotal, that.downParams.chooseNum)
                //that.downTotal = !isCheck ? that.maxList.download_num : that.downTotal
                that.downTotal = !isCheck ? (that.downParams.chooseNum != '自定义' ? (that.downParams.chooseNum || that.downTotal) : that.downParams.custom) : that.downTotal
                // console.log('downTotal ', that.downTotal)
                let data = {
                    group_type: !isCheck ? (that.downParams.downType == 1 ? 1 : (that.downParams.downType == 5 ? 2 : (that.downParams.downType == 3 ? that.params.group_type : 0))) : that.params.group_type,
                    name: isCheck || that.downParams.downType == 3 ? that.params.name : '',
                    province_id: isCheck || that.downParams.downType == 3 ? that.params.province_id : -1,

                }
                if (!isCheck) {
                    // let startTime = that.loadChoose == 2 ? that.downParams.loadTime[0] : '00:00'
                    // let endTime = that.loadChoose == 2 ? that.downParams.loadTime[1] : '23:59'
                    data.num = that.downTotal
                    data.end_time = that.params.end_time
                    data.start_time = that.params.start_time
                    data.start_num = that.params.start_num
                    data.end_num = that.params.end_num
                    // data.end_time = `${that.downParams.time == 1 || that.downParams.time == 3 ? nowDataMethod() : getDays(1)[getDays(1).length - 1]}  ${endTime}`;
                    // data.start_time = `${that.downParams.time == 1 ? nowDataMethod() : (that.downParams.time == 2 ? getDays(1)[getDays(1).length - 1] : getDays(7)[getDays(7).length - 1])}  ${startTime}`;
                }
                // index == 0 && (data.today = nowDataMethod())
                let needJf = !isCheck ? await api.getRequirintegral(data) : ''
                //  await api.checkNeed({
                //     ...data,
                //     aunique_identifier_list: that.checkList,
                // })
                let isDown = isCheck ? that.checkMsg.is_download : needJf.response.is_download
                // console.log('isDown', isDown)
                if (isDown) {
                    // if (index != 2) {
                    // if (needJf.code == 200) {
                    // console.log('that.checkMsg', that.checkMsg)
                    that.loadIndex = !isCheck ? that.downParams.downType : index;
                    //console.log('loadIndex', that.loadIndex)
                    that.downTotal = !isCheck ? that.downTotal : that.checkMsg.total
                    that.downPwd = !isCheck ? needJf.response.aunique_identifier : that.checkMsg.aunique_identifier;

                    that.$emit('initCount')
                    that.nowTime = nowDataMethod()
                    that.downParams.txtName = '';
                    that.sureDown(index, !isCheck ? '' : that.checkMsg.time, true, 1)
                    addConsoleTxt(`下载标题:${that.downParams.title},下载类型：${index == 1 ? '链接' : '图片'}`)
                } else {
                    MessageBox.alert(isCheck ? that.checkMsg.msg : needJf.response.msg, '温馨提示', {
                        confirmButtonText: '取消',
                        callback: action => { }
                    })
                }
            },
            //批量下载

            //下载新方法(new)
            downNewMethod(index) {
                let that = this;
                // console.log('downNewMethod---', index)
                //return;
                // 无筛选条件的时候默认7天，就不存在无筛选条件的情况，判断可去掉
                // (index == 3 && !that.keyWord) || (index == 4 && !that.area)
                // if ((index == 1 && that.params.group_type != 1) || (index == 5 && that.params.group_type != 2)) {
                //     Message({
                //         message: `请先对${index == 3 ? '搜索关键词' : (index == 1 ? '个人群' : (index == 5 ? '企业群' : '地区筛选'))}进行采集，再下载~`,
                //         type: 'error'
                //     });
                //     return;
                // }
                that.downParams = JSON.parse(JSON.stringify(that.clonedownParams))
                // console.log('每次打开弹窗', that.downParams)
                // return;
                that.downParams.downType = index;
                index != 2 && that.downMaxNumber();
                that.loadChoose = 1;
                that.dialogVisible = true;
                that.$nextTick(() => {
                    that.downParams.title = `您正在：${index == 1 ? '下载个人群' : (index == 5 ? '下载企业群' : (index == 3 ? '下载已筛选' : (index == 2 ? '下载已勾选的' : '批量下载')))}二维码`
                })
            },
            //下载最大数量
            async downMaxNumber(val = false) {
                //  console.log('切换日期', val)
                let that = this;
                let res = await that.downMaxCount(val);
                res && (that.maxList = res)
            },
            //最大数量
            downMaxCount(isdownTime) {
                let that = this;
                // console.log('downMaxCount', that.downParams.downType)
                return new Promise(async (reslove, reject) => {
                    // let startTime = isdownTime || that.downParams.loadTime ? that.downParams.loadTime[0] : '00:00'
                    // let endTime = isdownTime || that.downParams.loadTime ? that.downParams.loadTime[1] : '23:59'
                    //要根据筛选条件来
                    let newParams = {
                        num: that.downParams.chooseNum ? (that.downParams.chooseNum == '自定义' ? (that.downParams.custom ? that.downParams.custom : 0) : that.downParams.chooseNum) : 0,
                        //num:  0,
                        // num: that.loadChoose == 1 && that.downParams.chooseNum ? (that.downParams.chooseNum == '自定义' ? (that.downParams.custom ? that.downParams.custom : 0) : that.downParams.chooseNum) : 0,
                        group_type: that.checkList.length == 0 ? (that.downParams.downType == 1 ? 1 : (that.downParams.downType == 5 ? 2 : (that.downParams.downType == 3 ? that.params.group_type : 0))) : that.params.group_type,
                        name: that.checkList.length != 0 || that.downParams.downType == 3 ? that.params.name : '',
                        province_id: that.checkList.length != 0 || that.downParams.downType == 3 ? that.params.province_id : -1,
                        end_time: that.params.end_time,
                        start_time: that.params.start_time,
                        start_num: that.params.start_num,
                        end_num: that.params.end_num,
                        // end_time: `${that.checkList.length != 0 || that.downParams.time == 1 || that.downParams.time == 3 ? nowDataMethod() : getDays(1)[getDays(1).length - 1]} ${endTime}`,
                        // start_time: `${that.checkList.length != 0 || that.downParams.time == 1 ? nowDataMethod() : (that.downParams.time == 2 ? getDays(1)[getDays(1).length - 1] : getDays(7)[getDays(7).length - 1])} ${startTime}`,
                    }
                    let maxResult = await api.getMaxDown(newParams)
                    //console.log('maxResult', maxResult)
                    // if (maxResult.code == 200 && maxResult.response) {
                    reslove(maxResult.code == 200 && maxResult.response ? maxResult.response : '')
                    // }
                })
            },
            //确定下载新方法
            async sureDownMethod() {
                let that = this;
                if(!that.isRechange) {
                    //检查创建文件夹
                    let address =`${that.info.save_address}`; 
                    if (!isDownAddress(address)) {
                        Message({
                            message: `下载路径[${address}]不存在`,
                            type: 'error'
                        });
                        return;
                    }
                    address += `\\${that.formatChineseDate(new Date())}`
                    if (!isDownAddress(address)) {
                        Message({
                            message: `创建保存路径文件夹失败，请检查文件夹权限`,
                            type: 'error'
                        });
                        return;
                    }

                    that.downParams.downDic = address
                    let isCheck = that.checkList.length != 0 ? true : false
                    if (!isCheck) {
                        if (that.downParams.chooseNum == '自定义' && !that.downParams.custom) {
                            Message({
                                message: `请输入下载数量~`,
                                type: 'error'
                            });
                            return;
                        }
                        if (that.downParams.chooseNum && that.downParams.custom && !(/(^[1-9]\d*$)/.test(that.downParams.custom))) {
                            Message({
                                message: `下载数量需为正整数`,
                                type: 'error'
                            });
                            return;
                        }
                        let maxResult = await that.downMaxCount();
                        that.downTotal = maxResult.download_num
                        console.log('确定时间段', maxResult, that.maxList)
                        //核对下载数据是否一致
                        if (maxResult.download_num == that.maxList.download_num) {
                            that.againSureDown(maxResult)
                        } else {
                            MessageBox.confirm('数据有变化，是否继续下载', '提示', {
                                confirmButtonText: '继续下载',
                                cancelButtonText: '取消',
                                type: 'warning'
                            }).then((action) => {
                                //  console.log('进回调了')
                                if (action == 'confirm') {
                                    that.againSureDown(maxResult)
                                }
                            })
                        }
                    } else {
                        that.dialogVisible = false;
                        that.downPicMethod(that.downParams.type == 3 ? 1 : 0)
                    }
                }
                else {
                    that.titleMethod()
                }
            },
            // 确定下载
            againSureDown(maxResult) {
                let that = this
                let isCheck = that.checkList.length != 0 ? true : false
                if (maxResult && maxResult.download_num) {
                    let downNumber = maxResult.download_num
                    let timeText = that.updateOptions.filter(item => item.value == that.dateValue)[0].label
                    if (!isCheck && ((downNumber < that.downParams.chooseNum) && that.downParams.chooseNum != '自定义' || ((downNumber < that.downParams.custom) && that.downParams.chooseNum == '自定义'))) {
                        Message({
                            message: downNumber ? `${timeText}只有${downNumber}条可以下载~` : `暂无可下载数据`,
                            type: 'error'
                        });
                        return;
                    }
                    that.dialogVisible = false;
                    that.downPicMethod(that.downParams.type == 3 ? 1 : 0)

                } else {
                    Message({
                        message: `暂无可下载数据~`,
                        type: 'error'
                    });
                }

            },

            // 轮询txt
            async downTxt(id, params) {
                let that = this;
                that.txtTime && clearTimeout(that.txtTime)
                let creatResult;
                if (id) {
                    creatResult = id;
                } else {
                    let res = await api.createDown(params)
                    if (res.code == 200 && res.response) {
                        creatResult = res.response.id
                        that.$emit('collectMethod', {
                            isCollect: true,
                            isShowCount: true,
                            count: 1,
                        })
                    } else {
                        Message({
                            message: res.msg ? res.msg : `创建下载任务失败~`,
                            type: 'error'
                        });
                        return;
                    }
                }
                // console.log('creatResult', creatResult)
                let txtResult = await api.getUserWait({
                    id: creatResult
                });
                if (txtResult.code == 200) {
                    if (!txtResult.response) {
                        //下载无结果时轮询,500ms一次
                        that.txtTime = setTimeout(() => {
                            that.downTxt(creatResult)
                        }, 500)
                        return;
                    }
                    let address = `${that.info.save_address}\\${that.downParams.downType == 1 ? '个人群' : (that.downParams.downType == 5 ? '企业群' : (that.downParams.downType == 3 ? '搜索群' : '地区下载'))}`;
                    let name = guid()
                    let txtRes = await downFileExt(txtResult.response, address, name + '.txt')
                    if (txtRes && txtRes.code == 200) {
                        that.$emit('collectMethod', {
                            isCollect: false,
                            type: 'down',
                        })
                        api.downSuccess({ aunique_identifier: creatResult }).then(res => {
                            console.log('成功回调', res)
                        })
                    } else {
                        api.downError({ aunique_identifier: creatResult }).then(res => {
                            console.log('失败回调', res)
                        })
                    }
                } else {
                    that.downTxt('', params)  //链接失效时重新创建任务
                    // console.log('txtResult唯一标识不存在')
                }
            },
            //定时请求下载
            async sureDown(index, time, data, count) {
                let that = this;
                let isCheck = that.checkList.length != 0 ? true : false
                //console.log('count', index, that.downTotal, that.downCount, data)
                if (((!data || data == null || data == 'null') && that.isDown) || !that.isDown || that.iserror || that.downCount >= that.downTotal) {
                    // console.log('why?')
                    getUser()
                    that.loadIndex = -1;
                    //console.log('that.downCompelte', that.checkList, that.downTotal)
                    that.defaultTable = that.defaultTable.map(item => {
                        //console.log('item.aunique_identifier', item.aunique_identifier)
                        if (that.downCompelte.includes(item.aunique_identifier)) {
                            item.is_download = '是'
                            item.is_to_view = '是'
                        }
                        if (that.checkList.length != 0 && that.checkList.includes(item.aunique_identifier)) {
                            item.operate = false
                        }
                        return item
                    })

                    that.$refs.table && (that.$refs.table.isTop = false)
                    that.checkList = [];
                    that.$emit('collectMethod', that.iserror ? {
                        isCollect: false,
                        total: that.downTotal,
                        type: 'down',
                        isDown: true
                    } : {
                        isCollect: false,
                        type: 'down',
                        errLen: that.errorArray.length
                    })
                    return;
                }
                let param = {
                    group_type: !isCheck ? (that.downParams.downType == 1 ? 1 : (that.downParams.downType == 5 ? 2 : (that.downParams.downType == 3 ? that.params.group_type : 0))) : that.params.group_type,
                    name: isCheck || that.downParams.downType == 3 ? that.params.name : '',
                    province_id: isCheck || that.downParams.downType == 3 ? that.params.province_id : -1,
                    aunique_identifier: that.downPwd,
                }
                param.type = index
                //console.log('group_type', that.params.group_type)
                //index == 0 && (param.today = that.nowTime)
                //index != 2 && (param.is_all = index) //这个值到底传啥?
                if (!isCheck) {
                    param.start_num = that.params.start_num
                    param.end_num = that.params.end_num
                    param.end_time = that.params.end_time
                    param.start_time = that.params.start_time
                    // param.end_time = `${that.downParams.time == 1 || that.downParams.time == 3 ? nowDataMethod() : getDays(1)[getDays(1).length - 1]} 23:59`
                    // param.start_time = `${that.downParams.time == 1 ? nowDataMethod() : (that.downParams.time == 2 ? getDays(1)[getDays(1).length - 1] : getDays(7)[getDays(7).length - 1])} 00:00`
                }
                let address;
                if (isCheck) {
                    address = `${that.downParams.downDic}\\${that.isDownCheck ? '已勾选' : (that.rememberParams == 1 ? '个人群' : (that.rememberParams == 2 ? '企业群' : (that.rememberParams == 3 ? '搜索群' : (that.rememberParams == 4 ? '地区下载' : '最近7天'))))}`;
                } else {
                    address = `${ that.downParams.downDic}\\${that.downParams.downType == 1 ? '个人群' : (that.downParams.downType == 5 ? '企业群' : (that.downParams.downType == 3 ? '搜索群' : '最近7天'))}`;
                }
                if (!isDownAddress(address)) {
                    that.loadIndex = -1;
                    Message({
                        message: `创建保存路径文件夹失败，请检查文件夹权限`,
                        type: 'error'
                    });
                    return;
                }
                addConsoleTxt(`接口请求时间:${nowDataMethod('ms')}`)
                let result = !isCheck ? await api.downList(param) : await api.checkOther({
                    ...param,
                    time: time,
                })
                addConsoleTxt(`接口请求成功时间:${nowDataMethod('ms')}`)
                if (result.code == 200) {
                    if (index != 1) {
                        // `${that.downCount+1}_${nowDataMethod('noraml')}`
                        that.downParams.txtName = result.response && result.response.name ? (that.downParams.type==1?result.response.name.replace(/[{};',./:"<>?!@#$%^&*_+；‘，。{}|：”“？！@#￥%……&*——+]*/g,''):`${that.downCount+1}${nowDataMethod('noraml')}`) : ''
                        //console.log('没进来????', that.downParams.txtName,that.downParams.type)
                        !that.downParams.txtName && (that.downParams.txtName = guid())
                    }
                    //console.log('that.downCount', that.downCount,count)
                    if (index == 1 && that.downCount == 0) {
                        that.downParams.txtName = nowDataMethod(true);
                    }
                    // console.log('时间戳', that.downParams.txtName, count, index)
                    if (top.window.require && that.downParams.txtName) {
                        //先弹出弹窗
                        count == 1 && that.$emit('collectMethod', {
                            isCollect: true,
                            count: 1,
                            total: that.downTotal,
                            type: 'down',
                        })
                        try {
                            let downResult = index == 1 ? await appendText(address, that.downParams.txtName + '.txt', result.response.group_qr_code) : await downFileExt(result.response.group_qr_code, address, that.downParams.txtName + '.jpg')
                            if (downResult && downResult.code && (downResult.code == 200 || downResult.code == 202)) {
                                if (downResult.code == 200) {
                                    addConsoleTxt(`下载结束时间:${nowDataMethod('ms')},下载条数:${that.downCount},下条数据的id:${result.response.aunique_identifier}`)
                                    that.downCompelte.push(that.downPwd)
                                    count != 1 && that.$emit('collectMethod', {
                                        isCollect: true,
                                        count: count,
                                        total: that.downTotal,
                                        type: 'down',
                                    })
                                    api.downAfter({ operation_list: [that.downPwd] })
                                }
                                downResult.code == 202 && (that.errorArray.push(that.downPwd))
                                //console.log('唯一标识', result.response.aunique_identifier)
                                that.downPwd = result.response.aunique_identifier
                                that.sureDown(index, !isCheck ? '' : result.response.time, result.response.aunique_identifier, count++) //202的时候能cout++不
                            }
                            else {
                                that.iserror = true
                                that.sureDown(index, '', '', count++)

                            }
                        }
                        catch (err) {
                            if (err && err.code && err.code == 203) {
                                Message({
                                    message: `下载路径不存在~`,
                                    type: 'error'
                                });
                                that.$emit('initCount')
                                that.iserror = true
                                that.sureDown(index, '', '', 0)
                            }
                            // console.log('报错了----', err)
                        }
                    }
                    else {
                        that.iserror = true
                        that.sureDown(index, '', '', count++)
                    }
                }

                else {
                    // console.log('next没有值？')
                }
            },

            clear(data) {
                this.$refs.table && (this.$refs.table.isTop = false)
                this.$refs.table && data && this.$refs.table.initTop()
            },
            checkMethod(data) {
                // console.log('我单选', data, this.checkList)
                if (this.checkList.includes(data.aunique_identifier)) {
                    !data.operate && this.checkList.splice(this.checkList.findIndex(item => item == data.aunique_identifier), 1)
                } else {
                    data.operate && this.checkList.push(data.aunique_identifier)
                }
                this.isDownCheck = this.checkList.length ? true : false
                //console.log(this.checkList, 'kkk---->', this.defaultTable)
                //this.checkList = data.map(item => item.aunique_identifier)
            },
            //清空
            async delMethod(index) {
                let that = this;
                if (!that.isRechange) {
                    that.isLoad = true;
                    // that.$refs.table.gridLoad(true);
                    that.rest()
                    this.checkList = [];
                    let result = index == 1 ? await api.getDel() : await api.getDelDown()
                    that.isLoad = false;
                    // that.$refs.table.gridLoad(false);
                    if (result.code == 200 && result.response.success) {
                        index == 1 && (that.codeList = '')
                        that.defaultTable = that.defaultTable.filter(item => index == 1 ? item.is_to_view == '否' : item.is_download == '否')?.map(item => {
                            return {
                                ...item,
                                operate: false
                            }
                        })
                        that.$refs.table.setData(that.defaultTable)
                        that.isLoadMore = true;
                        index == 2 && (that.downCompelte = [])
                        that.$emit('closeCircle', false)
                        Message({
                            message: `已清空完成~`,
                            type: 'success'
                        });
                    }
                }
                else {
                    this.titleMethod();
                }
            },
            //整个搜索
            changeMethod(index) {
                if (!this.isRechange || this.groupSet.not_opened_list_mode==2) {
                    this.rememberParams = index;
                    this.isLoadMore = true;
                    this.isSearch = true;
                    this.current = -1;
                    this.checkList = [];
                    this.isDownCheck = false;
                    if (this.keyWord) {
                        let replaceWord = this.keyWord.replace(/[\uff0c]/g, ",")
                        replaceWord.endsWith(',') && (replaceWord = replaceWord.substring(0, replaceWord.length - 1))
                        let len = replaceWord.split(",").length
                        if (len > 3) {
                            Message({
                                message: '您的搜索词语，超出了规定的最多3个！',
                                type: 'error'
                            });
                            return;
                        }
                    }
                    this.codeList = '';
                    this.autoplay = true;
                    this.params.page_index = 1;
                    this.$refs.table && (this.$refs.table.isTop = true)
                    this.getsetInterval()
                }
                else {
                    this.titleMethod();
                }
            },
            //个人/企业按钮切换 num -->0是全部 1是个人 2是企业
            btnMethod(num,daySearch) {
                if (!this.isRechange || this.groupSet.not_opened_list_mode == 2 || (!daySearch && this.groupSet.not_opened_list_mode == 1)) {
                    this.isLoadMore = true;
                    this.rest();
                    this.restMethod();
                    this.rememberParams = num;
                    let that = this;
                    that.current = num;

                    that.params = {
                        group_type: num,
                        name: '',
                        province_id: -1,
                        page_size: 30,
                        page_index: 1,
                        start_num: 0,
                        end_num: 0,
                        end_time: daySearch ? nowDataMethod() + ' 23:59:59' : null,  //吴晗注释
                        start_time: daySearch ? getDays(7)[getDays(7).length - 1] + ' 0:00:00' : null, //吴晗注释
                    }
                    this.$refs.table && (this.$refs.table.isTop = true)
                    that.codeList = '';
                    that.autoplay = true;
                    that.getsetInterval()
                }
                else {
                    this.titleMethod();
                }
            },
            //
            //定时
            getsetInterval() {
                let that = this;
                that.defaultTable = [];
                that.isLoad = true;
                that.getList();
            },
            //继续采集
            resumeMethod() {
                this.isClear = true;
                this.$refs.table && (this.$refs.table.isTop = true)
                this.getList();
                this.autoplay = true;
            },
            //下拉加载更多
            loadMore() {
                let that = this;
                if (!that.isLoadMore) { //没有数据的时候
                    Message.closeAll()
                    that.isLoad = false;
                    Message({
                        message: '沒有更多数据啦~',
                        type: 'info'
                    });
                    return;
                }
                that.params.page_index += 1;
                that.$refs.table && (that.$refs.table.isTop = false)
                that.isLoad = true;
                that.getList()
            },
            //获取群
            getList() {
                let that = this;
                if (!that.isLoadMore) { //没有数据的时候
                    that.isLoad = false;
                    return;
                }
                if (that.isSearch) {  //搜索进来
                    that.params = {
                        group_type: that.groupType, //群组类型 1个人群 2企业群
                        name: that.keyWord, //采集关键词
                        province_id: that.area ? that.area : -1,//区域
                        page_size: 30,
                        page_index: that.params.page_index,
                        start_time: that.dateValue ? that.dateValue.split('&&')[0] : null,
                        end_time: that.dateValue ? that.dateValue.split('&&')[1] : null,
                        start_num: parseInt(that.personNum.split('-')[0]),//开始数量
                        end_num: parseInt(that.personNum.split('-')[1]),//结束数量
                    }
                }
                api.getGroup(that.params).then(res => {
                    that.isLoad = false;
                    if (res.code == 200) {
                        if (res.response.data.length != 0) {
                            if (res.response.data.length < that.params.page_size) {
                                that.isLoadMore = false;
                            }
                            let data = res.response.data;
                            data.map(item => {
                                item.is_to_view = item.is_to_view ? '是' : '否'
                                item.is_download = item.is_download ? '是' : '否'
                                return {
                                    ...item,
                                    operate: false,
                                }
                            })
                            that.defaultTable = that.defaultTable.concat(data)
                            that.$refs.table.setData(that.defaultTable, that.params.page_index)
                        } else {
                            that.isLoadMore = false;
                            that.clear()
                            that.params.page_index == 1 && that.$refs.table.setData([])
                        }
                    } else {
                        that.clear()
                    }
                }).catch(err => {
                    if(err.code=='ERR_BAD_REQUEST' && that.isRechange) {
                        this.changeMethod()
                    }
                    that.isLoad = false;
                    that.loadAnimation && that.loadAnimation.close();
                    that.isLoadMore = false;
                    that.clear()
                })
            },

            //获取省
            getArea() {
                let that = this;
                api.getArea().then(res => {
                    // console.log('获取省', res)
                    res.code == 200 && (that.areaOptions = res.response.map(item => {
                        return {
                            label: item.name,
                            value: item.id,
                        }
                    }))
                    that.areaOptions.unshift({ label: '不限地区', value: -1 })
                })

            },
            titleMethod() {
                this.$emit('titleMethod')
            },
            //延迟计算高度
            countMethod() {
                let that = this;
                setTimeout(() => {
                    that.tableH = (this.$refs.collectPage.offsetHeight - that.$refs.collectLeftTop.offsetHeight - that.$refs.collectLeftTop.offsetHeight) + 'px'
                    that.timer = false
                }, 20)
            }
        }
    }
</script>
<style lang="less" scoped>
    .collectContainer {
        height: calc(100% - 44px)
    }

    .collectPage {

        height: 100%;

        .collextLeft {
            width: 76%;
            height: 100%;
        }

        .collectRight {
            width: 24%;
            padding-left: 10px;
            height: 100%;

            .collectCode {
                width: 100%;
                border: 1px solid #DCDCDC;
                height: 100%;
                /* height: calc(100% - 48px); */
                /* margin-top: 14px; */
            }

            .collectCodeTitle {
                background: #DCDCDC;
                line-height: 30px;
                width: 100%;
            }

            /* .collectRightButton {
                .downKeyWord:nth-child(2) {
                   

                }
            } */

            /* .downKeyWord {
                width: 50%;
            } */

            .codeText {
                width: 100%;
                height: calc(100% - 30px);
                align-items: flex-start;
            }

            .codeActive {
                align-items: center !important;
            }
        }

        .cLeftBtn {
            /* padding:0px 34px; */
            line-height: 30px;
            width: 105px;
            position: relative;
            display: block;

            &:after {
                content: '';
                position: absolute;
                bottom: -7px;
                left: 50%;
                /* margin-left: -50%; */
                width: 0px;
                height: 0px;
                background: #FED46E;
                transform: rotate(50deg) translateX(-50%);
            }
        }

        .cLeftBtn.cur {
            &:after {
                width: 10px;
                height: 10px;
            }
        }

        .collectLeftTop {
            .cLeftBtn:nth-child(2) {
                margin: 0 12px;
            }
        }

        .cLeftSearch {
            padding-left: 12px;

            input {
                width: 370px;
                border: 1px solid #DCDCDC;
                line-height: 29.5px;
                padding: 0 10px;
                border-right: 0;
                box-sizing: border-box;
                height: 29.5px;
                display: block;
                text-align: left;
                font-size: 12px;
            }

            .lastBorder {
                /deep/ .el-input__inner {
                    border-right: 1px solid #DCDCDC;

                }
            }

            /deep/ .el-input {
                width: 140px;
            }

            /deep/ .el-input__inner {
                padding: 0 20px 0 0px;
                font-size: 12px;
                text-align: center;

            }
        }

        .collectTop {
            margin-bottom: 14px;
            position: relative;
        }

        .collectLeftTop {
            position: relative;
            /* width: 100%; */
            /* padding-bottom: 14px; */
        }

        .codeImg {
            width: 100%;
            height: 100%;

            .codeShow {
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 280px;
                    display: block;
                    /* margin: 10px auto; */
                }

                .codeShowText {
                    height: calc(100% - 280px);
                    overflow-y: auto;
                    padding: 0px 21px;

                    p {
                        margin: 10px 0;
                    }

                    .codeShowTextMargin {
                        margin-left: 10px;
                    }
                }
            }

        }
    }

    .noData {
        p:first-child {
            margin-bottom: 4px;
        }

        p:last-child {
            padding-left: 12px;
        }

        .mouse {
            /* position:absolute; */
            width: 10px !important;
            bottom: 0;
            right: 0;
            padding-top: 12px;
            margin-left: 5px;
        }
    }

    .collectBottom {
        width: 100%;
        padding-top: 8px;
        justify-content: space-between;

        li {
            width: 160px;
            position: relative;
            overflow: hidden;
            margin: 0px 5px;
            justify-content: space-between;

            img {
                width: 100%;
                height: 30px;
            }

            p {
                position: absolute;
                width: 100%;
                /* // line-height: 70px; */
                top: 50%;
                left: 0;
                transform: translateY(-50%);

            }

            .active {
                color: #409EFF;
            }
        }

        li:first-child {
            margin-left: 0px;
        }

        li:last-child {
            margin-right: 0px;
        }


    }

    .wxTable {
        width: 100%;
        height: calc(100% - 38px);
    }

    .collectCodeTitle {
        width: 90px;
        line-height: 30px;
    }

    .restBtn {
        margin-left: 10px;
        /* width: 62px; */
    }

    .downDialog {
        li {
            padding: 8px 0;
        }

        .downRadio {
            margin: 10px 0;
        }

        .downNow {
            color: #999;
        }

        .downToast {
            color: #FB3838
        }

        .buttonLi {
            padding: 2px 10px;
            border: 1px solid #DCDCDC;
            margin: 0px 5px;
            border-radius: 2px;
            color: #666;
            cursor: pointer;

            &.active {
                border-color: #409EFF;
                background: #409EFF;
                color: #fff;
            }
        }


        /deep/ .el-input {
            width: 160px;
        }

        /deep/ .el-input__inner {
            border-right: 1px solid #DCDCDC;
            border-radius: 2px;
            height: 26px;
        }



        .timePickerComponent {
            width: 50%;
            margin: 10px 0;

            /deep/ .el-input__inner {
                height: 26px;
                line-height: 26px;
            }

            /deep/ .el-range-input {
                line-height: 26px;
                font-size: 12px;
            }

            /deep/ .el-range-separator {
                line-height: 26px;
            }
        }
    }


    /deep/ .el-input {
        width: 138px;
    }

    /deep/ .el-input__inner {

        border-radius: 0px;
        border: 1px solid #DCDCDC;
        border-right: 0;
        color: #606266;
        height: 29.5px;
        line-height: 29.5px;
        padding: 0 10px;
        width: 100%;
        font-size: 12px;
    }

    /deep/ .el-input__icon {
        line-height: 26px;
    }

    /deep/ .el-select-dropdown__item.selected {
        color: #0B41D7;

    }

    /deep/ .el-select {
        .el-input__inner:focus {
            border-color: #DCDCDC;
        }

        .el-input.is-focus {
            .el-input__inner {
                border-color: #DCDCDC;
            }
        }
    }

    /deep/ .el-carousel__indicators--vertical {
        display: none;
    }

    /deep/ .el-dialog__body {
        padding: 10px 20px;
    }

    /deep/ .el-dialog__footer {
        text-align: center;
    }

    @media (min-width: 1440px) {
        .collectBottom {
            justify-content: flex-start;

            li {
                margin: 0px 15px;
            }
        }

        .collectPage {
            .codeImg {
                .codeShow {
                    img {
                        height: 300px;
                    }
                }
            }
        }
    }

    @media (min-width: 1660px) {
        .collectPage {
            .cLeftSearch {
                input {
                    width: 158px;
                }
            }

            .collectRight {
                .collectCode {
                    height: calc(100% - 50px);
                }

                .collectCodeTitle {
                    line-height: 30px;
                }

                /* .collectCodeTitle {
                line-height: 45px;

            } */

                /* .codeImg {
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                  
                } */
            }
        }

    }
</style>